export const palette = {
  neonBlue: '#4264fd',
  lavender: '#dfe5f9',
  lavender2: '#f0f3fc',
  whiteSmoke: '#ededed',
  cocoaBrown: '#2f1f19',
  cocoaBrown2: '#2e1e18',
  heatheredGrey: '#98918a',
  white: '#fff',
  backgroundTertiary: '#f8f8f8',
  wildSand: '#f4f2ef',
  wildSandOpacity: 'rgba(244, 242, 239, =7)',
  whisper: '#fdfcfc',
  darkTan: '#946952',
  greyBlackSqueeze: '#fafaf9',
  greyAlabaster: '#faf9f7',
  greyPaleSlate: '#c5c1bc',
  greyDesertStorm: '#f6f4f1',
  greyBonJour: '#ddd6cf',
  greyWildSand: '#eae5e1',
  yellow: '#ffdd2c'
};
