import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 15px;
  white-space: nowrap;

  &:disabled {
    ${({ theme: { colors }, state }) =>
      state === 'link'
        ? { color: colors.text.secondary }
        : { backgroundColor: '#a5c5fc' }}
  }

  ${({ theme: { borderRadius, fontFamily, button }, isSmall, state }) => {
    return {
      ...button[state],
      width: isSmall ? 'max-content' : '100%',
      borderRadius,
      fontFamily
    };
  }}

  ${({ styles }) => styles}
`;

export const btnFixedAtBottomStyles = styles => css`
  width: calc(100% - 30px);
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  margin: 15px 15px 20px;
  flex-grow: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  ${styles}
`;
