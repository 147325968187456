import * as React from 'react';
import PropTypes from 'prop-types';
import { palette } from '../styles/_palette';

const IconPropTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

export const AngleDownIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 13}
    height={props.height || 8}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill || palette.white}
      d="M1.524.5.5 1.52l6 5.98 6-5.98L11.476.5 6.5 5.46 1.524.5Z"
    />
  </svg>
);
AngleDownIcon.propTypes = IconPropTypes;

export const CloseIcon = props => {
  const w = props.width || 11;
  const h = props.height || w;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      viewBox={`0 0 ${w} ${h}`}
      fill="none"
    >
      <path
        d="M14.7845 1.75283L13.4991 0.5L7.39227 6.45182L1.28545 0.5L0 1.75283L6.10682 7.70465L0 13.6565L1.28545 14.9093L7.39227 8.95748L13.4991 14.9093L14.7845 13.6565L8.67773 7.70465L14.7845 1.75283Z"
        fill={props.fill || palette.white}
      />
    </svg>
  );
};
CloseIcon.propTypes = IconPropTypes;

export const CloseIconChip = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <path
        d="M10.5 0.869458L9.63054 0L5.5 4.13054L1.36946 0L0.5 0.869458L4.63054 5L0.5 9.13054L1.36946 10L5.5 5.86946L9.63054 10L10.5 9.13054L6.36946 5L10.5 0.869458Z"
        fill={props.fill || palette.white}
      />
    </svg>
  );
};
CloseIconChip.propTypes = IconPropTypes;
