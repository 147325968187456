import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { locationGroupDispatcher } from '../../utils/helpers';

const CheckboxButton = ({
  data,
  name,
  stat,
  cypress,
  isFullName,
  labelClass,
  withGroupType,
  currentValues,
  onChangeHandler
}) => {
  const { t } = useTranslation();
  const { id, name: locationName, locationGroupId, parent, fullName } = data;

  return (
    <label
      data-stat={stat}
      className={classNames('search-checkbox-label', labelClass)}
    >
      <div className="search-checkbox-container">
        <input
          value={id}
          name={name}
          type="checkbox"
          id={`location_ids_${id}`}
          onChange={onChangeHandler}
          data-cy={`${cypress}-${data.id}`}
          checked={currentValues?.includes(id)}
          className="search-checkbox input-hidden"
        />
        <div>
          <div className="search-checkbox__text" data-cy={`${cypress}-name`}>
            {isFullName ? fullName : locationName}
          </div>
          {withGroupType && (
            <div className="search-checkbox__group-type">
              {locationGroupDispatcher(locationGroupId, parent, t)}
            </div>
          )}
        </div>
        <span className="search-checkbox__mark" />
      </div>
    </label>
  );
};

CheckboxButton.propTypes = {
  stat: PropTypes.string,
  data: PropTypes.object,
  name: PropTypes.string,
  cypress: PropTypes.string,
  isFullName: PropTypes.bool,
  labelClass: PropTypes.string,
  withGroupType: PropTypes.bool,
  currentValues: PropTypes.array,
  onChangeHandler: PropTypes.func
};

export default CheckboxButton;
